





















import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import { BFormInput, BFormGroup } from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
  },
})
export default class InputComponent extends Vue {
  @ModelSync("value", "input")
  inputField!: Object | null;

  @Prop()
  label!: String;

  @Prop()
  type!: String;

  @Prop()
  placeholder!: String;

  @Prop()
  disabled!: Boolean;

  @Prop({ default: true, type: Boolean })
  required!: Boolean;

  get inputData() {
    return this.inputField ? this.inputField : "";
  }

  set inputData(value) {
    if (value !== "") {
      console.log(value);
      this.inputField = value;
    }
  }
}
