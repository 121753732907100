var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c(
                    "h6",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.formLabel ? _vm.formLabel : "Address") +
                          "\n        "
                      ),
                      _vm.withOverride
                        ? _c("feather-icon", {
                            staticClass: "cursor-pointer text-secondary",
                            attrs: { icon: "EditIcon" },
                            on: { click: _vm.onEditClick },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("input-component", {
            attrs: {
              value: _vm.practiceAddress ? _vm.practiceAddress.address1 : null,
              placeholder:
                _vm.$data._.isEmpty(_vm.addressOverride) == false
                  ? _vm.addressOverride.address1
                  : "Enter Address",
              label: "Address",
              disabled: _vm.editButtonAllow,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue("address1", $event)
              },
            },
          }),
          _c("input-component", {
            attrs: {
              required: false,
              value: _vm.practiceAddress ? _vm.practiceAddress.address2 : null,
              placeholder:
                _vm.$data._.isEmpty(_vm.addressOverride) == false
                  ? _vm.addressOverride.address2
                  : "Enter Address 2",
              label: "Address 2",
              disabled: _vm.editButtonAllow,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue("address2", $event)
              },
            },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("input-component", {
                    attrs: {
                      value: _vm.practiceAddress
                        ? _vm.practiceAddress.city
                        : null,
                      placeholder:
                        _vm.$data._.isEmpty(_vm.addressOverride) == false
                          ? _vm.addressOverride.city
                          : "Enter City",
                      label: "City",
                      disabled: _vm.editButtonAllow,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateValue("city", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: { label: "State", "label-for": _vm.uuid("State") },
                    },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "State",
                          rules:
                            !_vm.editButtonAllow && _vm.required
                              ? "required"
                              : "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-select", {
                                  attrs: {
                                    id: _vm.uuid("State"),
                                    value: _vm.practiceAddress
                                      ? _vm.practiceAddress.state
                                      : undefined,
                                    options: _vm.states,
                                    disabled:
                                      _vm.editButtonAllow || !_vm.states,
                                    "text-field": "name",
                                    "value-field": "id",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateValue("state", $event)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: undefined,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$data._.isEmpty(
                                                        _vm.addressOverride
                                                      ) == false
                                                        ? _vm.states.filter(
                                                            function (x) {
                                                              return (
                                                                x.id ==
                                                                _vm
                                                                  .addressOverride
                                                                  .state
                                                              )
                                                            }
                                                          )[0].name
                                                        : "Enter State"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Country",
                        "label-for": _vm.uuid("Country"),
                      },
                    },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "Country",
                          rules:
                            !_vm.editButtonAllow && _vm.required
                              ? "required"
                              : "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-select", {
                                  attrs: {
                                    id: _vm.uuid("Country"),
                                    value: _vm.practiceAddress
                                      ? _vm.practiceAddress.country
                                      : undefined,
                                    options: _vm.options,
                                    disabled: _vm.editButtonAllow,
                                    "text-field": "name",
                                    "value-field": "id",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateValue("country", $event)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: undefined,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$data._.isEmpty(
                                                        _vm.addressOverride
                                                      ) == false
                                                        ? _vm.options.filter(
                                                            function (x) {
                                                              return (
                                                                x.id ==
                                                                _vm
                                                                  .addressOverride
                                                                  .country
                                                              )
                                                            }
                                                          )[0].name
                                                        : "Enter Country"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("input-component", {
                    attrs: {
                      value: _vm.practiceAddress
                        ? _vm.practiceAddress.zip
                        : null,
                      placeholder:
                        _vm.$data._.isEmpty(_vm.addressOverride) == false
                          ? _vm.addressOverride.zip
                          : "Enter Zip Code",
                      label: "Enter Zip Code",
                      disabled: _vm.editButtonAllow,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateValue("zip", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }